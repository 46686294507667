section {
  padding: 80px 0;

  &#fonctionnement {
    padding-top: 0;
    background: #EDEEF0;

    li {
      font-weight: 900;
      font-size: 16px;
      font-family: ChunkFive, sans-serif;
      p {
        font-weight: normal;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    }
  }

  &#tarif_V2 {
    padding-top: 0;
  }

  #devices {
    margin-top: 50px;
  }
}

// La section avantage, on supprime le padding pour gagner de l'espace et permettre l'affichage de la suite sur mobile
#avantages {
  padding: 0;
}