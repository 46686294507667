//image with the hand on the index
div.mano-container {
  position: relative;

  .img-fluid {
    max-width: 100%;
    max-height: 500px;
    /*margin-left: 175px;*/
  }

  @media (max-width: $screen-sm-max) {
    .img-fluid {
      margin-left: 0px;
    }
  }
}
