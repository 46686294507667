nav.navbar-default {
  // background: $main-color;
  // border: $main-color;
  border-radius: 0;
  margin-bottom: 0;

  .navbar-nav > li {
    a, a:hover, a:focus, a:visited {
      font-family: ChunkFive, sans-serif;
      color: white;
      text-decoration: none;
      padding: 30px 10px;
      font-size: 14px;
    }
  }

  button.navbar-toggle.collapsed {
    color: white;
    border-color: currentColor;
    &:hover, &:focus, &:active {
      background-color: darken($main-color, 20%);
    }
  }
  .navbar-toggle .icon-bar {
    background-color: white;
  }

  #logo {
    max-width: 289px;

  }
}

//correspond au menu (partie de droite de la navbar avec les boutons sans prendre en compte le logo)
#menu {
  .bordered a {
    border: 1px solid white;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 25px;
    margin-bottom: 25px;

    @media (min-width: 1200px) {
      margin-left: 30px;
    }
  }

  .login_button a {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 25px;
    margin-bottom: 25px;
  
    margin-left:10px;
  }
}
