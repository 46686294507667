//button for editing the number in the index
#edit_number {
  height: 44px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #EEE;
  background-color:#eeeeee;
}


//class which is added to the edit number button in the index if the number is valid
.invisible_feedback {
  width: 0;
}

//bouton de validation de mail et numéro de téléphone avant envoi au back de la demande de géoloc
#go {
  font-size : 20px;
}

//bouton d'action de géoloc sur mobile de la page d'acceuil
#validate-button-mobile {
  .btn {
    font-size: 18px;
  }
}

.btn:hover {
  color: white!important;
}
.btn {
  color: white!important;
}