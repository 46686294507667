
footer {
  background: $black-background url("/images/background.webp") center center no-repeat;
  background-size: cover;
  color: white;
  padding: 80px 0 0;

  a {
   
    &:hover, &:focus {
     
      text-decoration: underline;
      
    }
  }
}

footer {

  padding: 80px 0;
  h2 {
    text-align: left;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      &:before{
        content: ">";
      
        padding-right: 15px
      }
    }
  }
}

// Meme chose pour le footer
#global_footer {
  padding-left:0;
  padding-right:0;
}
