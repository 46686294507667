// mentions legales presentes sur la cgv et dans l'espace contact
div.gib-mentions-legales {
  max-width: 1000px;
  margin: auto;

  .spacer {
    width: 100%;
    min-height: 50px;
    display: block;
  }
}
