//footer a{
  // padding: 20px 0px!important; ?? pourquoi Romain ?
//}
// .btn-primary {
//   background-color: #e20979!important;
// }

// h4.media-heading{
//   color: #e20979!important;
// }
// Meme chose pour le container total (défini avant le header, et se ferme après le footer)
#global_container {
  padding-left:0;
  padding-right:0;
  width:100%;
  overflow: auto; //empêche les éléments plus grand de faire apparaitre une barre de scroll, ou une bande blanche sur mobile
}

html,body {      //Permet de supprimer la petite bande blanche sur la droite du site
  width: 100%;   //Bande blanche due aux container de bootstrap qui crée une marge de 15px
  height: 100%;
  margin: 0;
  padding: 0;
}

hr {
  border: 2px solid;
  width: 200px;
  margin: 40px auto;
}

.main-color {
  color: $main-color;
}

.spacer {
  width: 100%;
  min-height: 40px;
}

// @media  (max-width: $screen-md-min) {
//   font-size: 14px !important;
//}



