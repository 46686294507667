/* Section Tarif */
#tarif_V2 {
  .elem-color {
    color:$main-color;
  }
  li {
    list-style-type:none;
  }
  .btn {
    white-space:normal;
  }
  .text-style {
    font-weight: bold;
    font-size:18px;
  }
  #tarif_left_prix {
    margin-top:10px;
    p {
      display:inline;
    }
    .glyphicon-arrow-right {
      font-size:22px;
    }
  }
  .spacer {
    display:block;
    margin: 5px 0 10px 0;
    width: 100%;
  }
  ul p {
    display:inline;
  }
  .glyphicon {
    font-size:17px;
  }
  #tarif_foot {
    padding:50px;
  }
  a + .glyphicon-arrow-right {
    color: white;
  }
  .well {
    min-height:505px;
  }
  @media only screen and (max-device-width : 800px) {
    .glyphicon-arrow-down {
      margin-bottom: 10px;
    }
    #tarif_left_link {
      margin:10px;
    }
    .border-right {
      border-width:0;
    }
    .well {
      margin-bottom: 0;
    }
  }
}
