header#default {
  background: $black-background url("/images/background.webp") center center no-repeat;
  background-size: cover;
  color: white;
  padding: 20px 0 0;

  a {
    color: currentColor;
    &:hover, &:focus {
      color: currentColor;
      text-decoration: underline;
      text-decoration-color: $main-color;
    }
  }
}


header {
  padding-bottom: 0;
  overflow: hidden;
  h1 {
    font-size: 32px;
  }
  p {
    text-align: justify;
  }

  div.col-md-8 {
    text-align: right;
  }
}

// Modification de la barre de header sur moyen écran
@media (max-width: 1500px) and (min-width: $screen-sm-min) {
  .navbar-header, .navbar-brand {
    text-align: center;
  }
  #logo {
    display: inline-block;
  }
}

/* Ajout d'un id sur le logo de l'acceuil pour qu'il ne dépasse pas sur mobile avec le navigateur chrome */

@media only screen
and (max-device-width : 374px) {
  #logoAcceuil {
    width:300px;
  }
}

//Permet au header de prendre toute la place possible
#global_header {
  padding-left: 0;
  padding-right: 0;
}

// modification du grid float breakpoint du header avec css (rupture du header sur écran de moyenne taille)
@media (max-width: 1140px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden !important;
  }
}

// modification de la taille du logo de localiser mobile lorsque le header break
@media (max-width: 1140px) {
  #logo{
    height: 27px;
  }
}

//mail affiché pour l'admin (dans le header)
a.account_link {
  color: #333 !important;
  font-size: 0.8em !important;
}
@media all and (min-width: 991px) and (max-width: 1200px) {
  #default div div:nth-child(1) div:nth-child(4) p:nth-child(2){
    padding: 0!important;
  }
}
