// style for the number input on the index
#portabilite-container, #userknown-container {
  margin-top:10px;
  p {
    font-weight: bold;
    span {
      min-width: 180px;
      display: inline-block;
    }
  }
  img {
    max-width: 70px;
    display: inline-block;
    margin-left:0px;
  }
}


