@font-face {
  font-family: "ChunkFive";
  font-style: normal;
  font-weight: normal;
  src: local("/fonts/ChunkFive-Roman"),
    url("/fonts/Chunkfive.woff") format("woff");
  font-display: swap;
}
p {
  line-height: 1.8em;
}
h2 {
  margin-top: 50px;
}
h1,
h2,
h3,
h4 {
  font-family: ChunkFive, sans-serif;
}
h2 {
  text-align: center;
}

// h4.media-heading {
//   color: $main-color;
// }

// p.main-color {
//   color: $main-color;
// }

p.count {
  text-align: center;
  margin: 150px;
  font-size: 10em;
  font-weight: bold;
  font-family: ChunkFive;
}

.bigtext {
  font-size: 18px;
}

.small_text {
  font-size: 0.8em;
}

.upper {
  text-transform: uppercase;
}

.div_info p {
  color: $secondary_color;
}

//hidden text in a button on the index in the edit number part
#feedback {
  left: -38px;
  top: 5px;
}

.gib-mentions-legales {
  h1 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

#reset_h1 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

//Icone de l'acceuil en forme de localisation pour les mettre dans la couleur du site
// .info-additionelle {
//   .glyphicon {
//     color: $main-color;
//   }
// }

.info-additionelle {
  font-size: 15px;
}

//Section à droite du portable
.infos-sup {
  margin-top: 30px;
}

.info-right {
  height: 80px;
}

.nopadding {padding: 0;}

//Changement de la police du h1 de l'index, pour lui permettre d'apparaitre sur deux lignes sur mobile, et une seule sur machine
#title-site {
  font-size: 24px;
}

//TrustPilot styles

.bold-underline {
  font-family: "Segoe UI", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.hidden {
  display: none;
}
.tp-widget-loader,
.tp-widget-fallback {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
#fallback-logo {
  display: none;
}
.no-svg #fallback-logo {
  display: inline-block;
}
.tp-widget-wrapper {
  height: 100%;
  margin: 20px 0 20px 18px;
  max-width: 750px;
  opacity: 0;
  position: relative;
  transition: opacity 1s ease;
}
.tp-widget-wrapper.visible {
  opacity: 1;
}
.tp-stars .tp-star__canvas,
.tp-stars .tp-star__canvas--half {
  fill: #dcdce6;
}
.tp-stars .tp-star__shape {
  fill: #fff;
}
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas,
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-widget-loader,
.tp-widget-fallback {
  max-width: 50%;
  opacity: 0;
  text-align: center;
  width: 400vh;
}
.tp-widget-loader svg,
.tp-widget-fallback svg {
  width: 100%;
}
@media screen and (min-width: 400px) {
  .tp-widget-loader,
  .tp-widget-fallback {
    max-width: 200px;
  }
}
.tp-widget-loader--loaded {
  opacity: 1;
}
.bold-underline {
  border-bottom: 1px solid rgba(25, 25, 25, 0.6);
}
.bold-underline:hover {
  border-color: #191919;
}
.dark {
  color: #fff;
}
.dark a {
  color: #fff;
}
.dark .bold-underline:not(.bold-underline--single-theme) {
  border-color: rgba(255, 255, 255, 0.6);
}
.dark .bold-underline:not(.bold-underline--single-theme):hover {
  border-color: #fff;
}
.dark .tp-logo__text {
  fill: #fff;
}
.dark .tp-widget-loader .tp-logo__text {
  fill: #191919;
}

img {
  border: 0;
}
svg {
  display: block;
  width: 100%;
}
a {
  color:black;
  text-decoration: none;
}
.tp-widget-wrapper {
  max-width: 255px;
}
.tp-widget-logo {
  margin: 0 0 8px;
  width: 55%;
}
.tp-widget-stars {
  margin: 0 0 8px;
  width: 94%;
}
.tp-widget-businessinfo {
  font-size: 11px;
  line-height: 1.2;
}
.tp-widget-businessinfo__trustscore,
.tp-widget-businessinfo__total {
  text-transform: none;
}
@media screen and (min-width: 200px) {
  .tp-widget-logo {
    max-width: 140.25px;
  }
  .tp-widget-businessinfo {
    font-size: 14px;
    max-width: 255px;
  }
}
@media screen and (min-width: 235px) {
  .tp-widget-businessinfo__trustscore,
  .tp-widget-businessinfo__total {
    display: inline-block;
    vertical-align: middle;
  }
  .tp-widget-businessinfo__trustscore::after {
    content: "|";
    margin: 0 2px;
  }
}
